<template>
	<div>
		<el-divider content-position="left">审批流程</el-divider>
		<div v-if="nodes && nodes.length > 0" style="margin-top: 20px">
			<el-timeline :hide-timestamp="true">
				<el-timeline-item
					v-for="(node, index) in nodes"
					:key="index"
				>
					<div>
						<span>{{ node.title }}&emsp;</span>
						<el-button
							size="mini"
							type="primary"
							v-if="node.assigneeType == 'SELF_SELECT'"
							@click="addUser(node)"
							style="padding: 2px 8px;"
						>
							添加
						</el-button>
						<el-tag size="mini" style="margin-left: 10px;" v-if="node.users.length > 1 && node.mode == 'ORDERLY'">依次审批</el-tag>
						<el-tag size="mini" style="margin-left: 10px;" v-if="node.users.length > 1 && node.mode == 'DISORDER'">多人审批</el-tag>
						<el-tag size="mini" style="margin-left: 10px;" v-if="node.users.length > 1 && node.mode == 'OR'">一人审批</el-tag>
					</div>
					<div style="margin-left: 20px; margin-top: 10px">
						<span>{{ getNicknames(node.users) }}</span>
					</div>
				</el-timeline-item>
			</el-timeline>
		</div>
		<user-picker
			type="user"
			ref="userPicker"
			:multiple="selectMultiple"
			:selected="selectUsers"
			@ok="selected"
		></user-picker>
	</div>
</template>

<script>
import UserPicker from '@/components/UserPicker'
export default {
	components: { UserPicker },
	props: {
		orderType: {
			type: String,
			required: true
		},
		variables: {
			default: {},
			type: Object
		}
	},
	data() {
		return {
			loading: false,
			nodes: [],
			selectNode: '', // 选中的节点
			selectMultiple: false,
			selectUsers: [],
			auditors: {}
		}
	},
	watch: {
		variables: {
			deep: true,
			immediate: true,
			handler(newV, oldV) {
				this.initFlowable()
			}
		}
	},
	methods: {
		async initFlowable() {
			this.auditors = {}
			this.selectNode = ''
			// 获取流程信息
			this.nodes = await this.$api.flowable.initAssignedUsers(
				this.orderType,
				this.variables
			)
			this.$emit('initNode', this.nodes)
			this.$emit('refresh', this.auditors)
		},
		addUser(node) {
			this.selectNode = node
			this.selectMultiple = node.multiple
			this.selectUsers = []
			this.selectUsers = this.auditors[this.selectNode.id]
			this.$refs.userPicker.show()
		},
		selected(select) {
			const _this = this
			this.nodes = this.nodes.map(item => {
				if (item.id == _this.selectNode.id) {
					let exist = item.users.filter(val => val.status || val.status == 0)
					let existUserIds = exist.map(item => item.id)
					_this.auditors[_this.selectNode.id] = select.filter(_item => !existUserIds.includes(_item.id))
					item.users = exist.concat(_this.auditors[_this.selectNode.id])
				}
				return item
			})
			// 更新父组件的自选审批人信息
			this.$emit('refresh', this.auditors)
		},
		getNicknames(arr) {
			return (arr || []).map(a => a.nickname).join('、')
		}
	}
}
</script>

<style lang="scss" scoped></style>
