<template>
	<w-dialog
		:border="false"
		closeFree
		width="600px"
		@ok="selectOk"
		:title="title"
		v-model="visible"
	>
		<div class="picker">
			<div class="candidate" v-loading="loading">
				<div>
					<el-input
						v-model="search"
						@input="searchUser"
						style="width: 95%"
						size="small"
						clearable
						placeholder="搜索人员"
						prefix-icon="el-icon-search"
					/>
				</div>
				<div class="user-items">
					<div class="user-item" style="margin-top: 5px">
						<el-checkbox
							v-model="checkAll"
							@change="handleCheckAllChange"
							:disabled="!multiple"
						>
							全选
						</el-checkbox>
					</div>
					<el-empty
						:image-size="100"
						description="似乎没有数据"
						v-show="users.length === 0"
					/>
					<div
						v-for="(user, index) in users"
						:key="index"
						class="user-item"
						@click="selectChange(user)"
					>
						<el-checkbox v-model="user.selected"></el-checkbox>
						<div
							style="
								display: flex;
								align-items: center;
								cursor: pointer;
							"
						>
							<el-avatar
								:size="35"
								:src="user.avatar"
								v-if="$utils.isNotEmpty(user.avatar)"
							/>
							<span v-else class="avatar">
								{{ getShortName(user.nickname) }}
							</span>
							<span class="name">{{ user.nickname }}</span>
						</div>
					</div>
				</div>
			</div>
			<div class="selected">
				<div class="count">
					<span>已选 {{ select.length }} 项</span>
					<span @click="emptySelected">清空</span>
				</div>
				<div class="user-items" style="height: 350px">
					<el-empty
						:image-size="100"
						description="请点击左侧列表选择数据"
						v-show="select.length === 0"
					/>
					<div
						v-for="(user, index) in select"
						:key="index"
						class="user-item"
					>
						<div style="display: flex; align-items: center">
							<el-avatar
								:size="35"
								:src="user.avatar"
								v-if="$utils.isNotEmpty(user.avatar)"
							/>
							<span v-else class="avatar">
								{{ getShortName(user.nickname) }}
							</span>
							<span class="name">{{ user.nickname }}</span>
						</div>
						<i class="el-icon-close" @click="clearSelected(index)"></i>
					</div>
				</div>
			</div>
		</div>
	</w-dialog>
</template>

<script>
export default {
	name: 'UserPicker',
	components: {},
	props: {
		title: {
			default: '请选择',
			type: String
		},
		multiple: {
			//是否多选
			default: false,
			type: Boolean
		},
		selected: {
			default: () => {
				return []
			},
			type: Array
		}
	},
	data() {
		return {
			visible: false,
			loading: false,
			checkAll: false,
			users: [],
			select: [],
			search: ''
		}
	},
	computed: {},
	watch: {
		selected:{
			deep:true,
			handler(newV,oldV){
				this.select=[...newV]
			}
		}
		
	},
	methods: {
		show() {
			this.visible = true
			this.init()
		},
		init() {
			this.checkAll = false
			this.users = []
			this.search = ''
			this.listUsers()
		},
		async listUsers() {
			try {
				this.loading = true
				this.users = await this.$api.flowable.listUsers() || []
				this.selectToLeft()
			} catch (err) {
				console.log(err)
			} finally {
				this.loading = false
			}
		},
		getShortName(name) {
			if (name) {
				return name.length > 1 ? name.substring(0, 1) : name
			}
			return '**'
		},
		async searchUser() {
			try {
				this.loading = true
				this.users = await this.$api.flowable.listUsers({
					nickname: this.search.trim()
				}) || []
				this.selectToLeft()
			} catch (err) {
				console.log(err)
			} finally {
				this.loading = false
			}
		},
		selectToLeft() {
			(this.users||[]).forEach(user => {
				for (let i = 0; i < this.select.length; i++) {
					if (this.select[i].id === user.id) {
						user.selected = true
						break
					} else {
						user.selected = false
					}
				}
			})
		},
		selectChange(user) {
			if (user.selected) {
				this.checkAll = false
				for (let i = 0; i < this.select.length; i++) {
					if (this.select[i].id === user.id) {
						this.select.splice(i, 1)
						break
					}
				}
				user.selected = false
			} else {
				user.selected = true
				if (!this.multiple) {
					this.users.forEach(_user => {
						if (user.id !== _user.id) {
							_user.selected = false
						}
					})
				}
				if (!this.multiple) {
					this.select = [user]
				} else {
					this.select.push(user)
				}
			}
		},
		emptySelected() {
			this.$confirm('您确定要清空已选中的项?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.recover()
			})
		},
		clearSelected(index) {
			let users = this.users
			for (let f = 0; f < 2; f++) {
				for (let i = 0; i < users.length; i++) {
					if (users[i].id === this.select[index].id) {
						users[i].selected = false
						this.checkAll = false
						break
					}
				}
				users = this.users
			}
			this.select.splice(index, 1)
		},
		recover() {
			this.select = []
			this.users.forEach(user => (user.selected = false))
		},
		handleCheckAllChange() {
			this.users.forEach(user => {
				if (this.checkAll) {
					if (!user.selected) {
						user.selected = true
						this.select.push(user)
					}
				} else {
					user.selected = false
					for (let i = 0; i < this.select.length; i++) {
						if (this.select[i].id === user.id) {
							this.select.splice(i, 1)
							break
						}
					}
				}
			})
		},
		selectOk() {
			this.$emit(
				'ok',
				Object.assign(
					[],
					this.select.map(user => {
						return {
							id: user.id,
							nickname: user.nickname,
							avatar: user.avatar
						}
					})
				)
			)
			this.visible = false
			this.recover()
		},

		close() {
			this.$emit('close')
			this.recover()
		}
	}
}
</script>

<style lang="less" scoped>
.candidate, .selected {
	position: absolute;
	display: inline-block;
	width: 278px;
	height: 400px;
	border: 1px solid #e8e8e8;
}

.picker {
	height: 402px;
	position: relative;
	text-align: left;
	.candidate {
		left: 0;
		top: 0;

		& > div:first-child {
			padding: 5px 10px;
		}
	}

	.selected {
		right: 0;
		top: 0;
	}

	.user-items {
		overflow-y: auto;
		height: 310px;

		.el-icon-close {
			position: absolute;
			right: 5px;
			cursor: pointer;
			font-size: larger;
		}
		/deep/ .user-item {
			display: flex;
			align-items: center;
			padding: 5px;
			margin: 0 5px;
			border-radius: 5px;
			position: relative;

			.el-checkbox {
				margin-right: 10px;
			}
			.avatar {
				width: 35px;
				text-align: center;
				line-height: 35px;
				background: #1890FF;
				color: white;
				border-radius: 50%;
			}
			.name {
				margin-left: 5px;
			}
			&:hover {
				background: #f1f1f1;
			}
		}
	}
}

.selected {
	border-left: none;

	.count {
		width: calc(278px - 20px);
		padding: 10px;
		display: inline-block;
		border-bottom: 1px solid #e8e8e8;
		margin-bottom: 5px;
		& > span:nth-child(2) {
			float: right;
			color: #c75450;
			cursor: pointer;
		}
	}
}

/deep/ .el-dialog__body {
	padding: 10px 20px;
}

.disabled {
	cursor: not-allowed !important;
	color: #8c8c8c !important;
}

::-webkit-scrollbar {
	float: right;
	width: 4px;
	height: 4px;
	background-color: white;
}

::-webkit-scrollbar-thumb {
	border-radius: 16px;
	background-color: #efefef;
}
</style>
