//发票申请
<template>
  <Page ref="page" v-loading="loading" :showReturn="!isCreate">
    <div>
      <el-form ref="form" label-width="140px" :model="params" :rules="rules">
        <div class="cm-flex-line">
          <el-form-item label="申请人：" prop="proposerCode">
            <SelectProposer
              :code.sync="params.proposerCode"
              :name.sync="params.proposerName"
              @change="proposerChange"
              :disabled="!isCreate"
            />
          </el-form-item>
          <el-form-item label="申请部门：" prop="applyDepartmentCode">
            <SelectDepartment
              ref="SelectDepartment"
              :code.sync="params.applyDepartmentCode"
              :name.sync="params.applyDepartmentName"
              disabled
            />
          </el-form-item>
        </div>
        <div class="cm-flex-line">
          <el-form-item label="关联服务收费单：" prop="relationOrderCode">
            <el-select
              style="width: 100%"
              v-model="params.relationOrderCode"
              filterable
              @change="changeRelationOrderCode"
              clearable
            >
              <el-option
                :label="a.label"
                :value="a.value"
                :key="a.value"
                v-for="a in sellOrderCodes"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="U8销售单号：" prop="sellOrderCode">
            <el-input
              v-model="params.sellOrderCode"
              placeholder="请输入U8销售单号"
              :readonly="existSellOrderCode"
            ></el-input>
          </el-form-item>
          <el-form-item label="仓库已发货：" prop="shipped">
            <template>
              <el-radio v-model="params.shipped" :label="1">是</el-radio>
              <el-radio v-model="params.shipped" :label="0">否</el-radio>
            </template>
          </el-form-item>
        </div>
        <div class="cm-flex-line">
          <el-form-item label="发票票种：" prop="invoiceType">
            <el-select style="width: 100%" v-model="params.invoiceType">
              <el-option
                :label="a.label"
                :value="a.value"
                :key="a.value"
                v-for="a in $constant.invoiceType"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="客户名称：" prop="clientCode">
            <SelectCustomer
              :code.sync="params.clientCode"
              :name.sync="params.clientName"
              @change="changeClient"
            />
          </el-form-item>
          <el-form-item label="客户税号：" prop="clientNo">
            <el-input
              v-model="params.clientNo"
              placeholder="请输入客户税号"
            ></el-input>
          </el-form-item>
        </div>
        <div class="cm-flex-line">
          <el-form-item label="电话号码：" prop="clientPhone">
            <el-input
              v-model="params.clientPhone"
              placeholder="请输入电话号码"
            ></el-input>
          </el-form-item>
          <el-form-item label="开户银行：" prop="clientBank">
            <el-input
              v-model="params.clientBank"
              placeholder="请输入开户银行"
            ></el-input>
          </el-form-item>
          <el-form-item label="银行账户：" prop="clientBankNo">
            <el-input
              v-model="params.clientBankNo"
              placeholder="请输入银行账户"
            ></el-input>
          </el-form-item>
        </div>
        <el-form-item label="客户地址：" prop="clientAddress">
          <el-input
            v-model="params.clientAddress"
            placeholder="请输入客户地址"
          ></el-input>
        </el-form-item>
        <el-form-item label="客户发票备注：" prop="remark">
          <el-input
            v-model="params.remark"
            placeholder="请输入备注"
            type="textarea"
            maxlength="255"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item prop="fileList" label="附件：">
          <UploadFile
            ref="UploadFile"
            v-model="params.fileList"
            :showAccept="false"
            :limit="3"
            multiple
            :preAppendixAddr="params && params.attachmentUrl"
          />
        </el-form-item>
        <el-divider content-position="left">物品清单</el-divider>
        <div
          style="display: flex; justify-content: end; margin-bottom: 10px"
          v-if="showCustomAdd"
        >
          <el-button
            type="primary"
            icon="el-icon-plus"
            size="mini"
            @click="addEvent()"
          >
            添加
          </el-button>
        </div>
        <el-table :data="params.detailUpdateInputs">
          <el-table-column header-align="center" label="料号">
            <template slot-scope="{ row, $index }">
              <el-form-item
                label=""
                label-width="0"
                :prop="'detailUpdateInputs.' + $index + '.code'"
                :rules="rules.code"
              >
                <SelectGoodsCode
                  :code.sync="params.detailUpdateInputs[$index].code"
                  :name.sync="params.detailUpdateInputs[$index].name"
                  v-if="showCustomAdd"
                />
                <el-input
                  v-model="params.detailUpdateInputs[$index].code"
                  readonly
                  v-else
                ></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column header-align="center" label="名称">
            <template slot-scope="{ row, $index }">
              <el-form-item
                label=""
                label-width="0"
                :prop="'detailUpdateInputs.' + $index + '.name'"
                :rules="rules.name"
              >
                <SelectGoodsName
                  :code.sync="params.detailUpdateInputs[$index].code"
                  :name.sync="params.detailUpdateInputs[$index].name"
                  v-if="showCustomAdd"
                />
                <el-input
                  v-model="params.detailUpdateInputs[$index].name"
                  readonly
                  v-else
                ></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column header-align="center" label="数量">
            <template slot-scope="{ row, $index }">
              <el-form-item
                label=""
                label-width="0"
                :prop="'detailUpdateInputs.' + $index + '.num'"
                :rules="rules.num"
              >
                <el-input
                  v-model="params.detailUpdateInputs[$index].num"
                  placeholder="请输入数量"
                  @input="
                    val =>
                      (params.detailUpdateInputs[$index].num =
                        $format.fmtNumber(val))
                  "
                  @blur="computeTotalAmount($index)"
                  :readonly="!showCustomAdd"
                ></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column header-align="center" label="单价">
            <template slot-scope="{ row, $index }">
              <el-form-item
                label=""
                label-width="0"
                :prop="'detailUpdateInputs.' + $index + '.price'"
                :rules="rules.price"
              >
                <el-input
                  v-model="params.detailUpdateInputs[$index].price"
                  placeholder="请输入单价"
                  @input="
                    val =>
                      (params.detailUpdateInputs[$index].price =
                        $format.fmtAmt(val))
                  "
                  @blur="computeTotalAmount($index)"
                  :readonly="!showCustomAdd"
                ></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column header-align="center" label="总价">
            <template slot-scope="{ row, $index }">
              <el-form-item
                label=""
                label-width="0"
                :prop="'detailUpdateInputs.' + $index + '.amount'"
              >
                <el-input
                  v-model="params.detailUpdateInputs[$index].amount"
                  readonly
                ></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <!-- <el-table-column header-align="center" label="备注">
            <template slot-scope="{ row, $index }">
              <el-form-item
                label=""
                label-width="0"
                :prop="'detailUpdateInputs.' + $index + '.remark'"
              >
                <el-input
                  v-model="params.detailUpdateInputs[$index].remark"
                  placeholder="请输入备注"
                ></el-input>
              </el-form-item>
            </template>
          </el-table-column> -->
          <el-table-column header-align="center" label=" " v-if="showCustomAdd">
            <template slot-scope="{ row, $index }">
              <el-form-item label="" label-width="0">
                <el-button type="text" @click="deleteEvent($index)">
                  删除
                </el-button>
              </el-form-item>
            </template>
          </el-table-column>
          <template slot="empty">
            <div>暂无数据，请添加</div>
          </template>
        </el-table>
      </el-form>
    </div>
    <FlowableStep
      orderType="8"
      :variables="variables"
      @initNode="setNodes"
      @refresh="setAuditors"
    ></FlowableStep>
    <template slot="bottom">
      <el-button
        type="primary"
        @click="submitEvent(1)"
        style="margin-right: 10px"
      >
        提交申请
      </el-button>
      <el-button type="info" @click="submitEvent(2)">保存草稿</el-button>
    </template>
  </Page>
</template>

<script>
import FlowableStep from '@/components/FlowableStep'
import applyMixins from '../../mixins/applyMixins'
export default {
  mixins: [applyMixins],
  components: { FlowableStep },
  data() {
    return {
      existSellOrderCode: false,
      sellOrderCodes: [],
      rules: {
        proposerCode: [{ required: true, message: '请选择申请人' }],
        applyDepartmentCode: [{ required: true, message: '请选择申请人部门' }],
        shipped: [{ required: true, message: '请选择' }],
        invoiceType: [{ required: true, message: '请选择' }],
        clientCode: [{ required: true, message: '请选择' }],
        code: [{ required: true, message: '请选择' }],
        name: [{ required: true, message: '请选择' }],
        num: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (!value || Number(value) === 0) {
                callback('请输入非0整数')
              } else {
                callback()
              }
            }
          }
        ],
        price: [{ required: true, message: '请输入' }]
      },
      showCustomAdd: true,
      params: {
        clientNo: '',
        clientPhone: '',
        clientBank: '',
        clientBankNo: '',
        clientAddress: '',
        detailUpdateInputs: [],
        auditors: {}
      },
      nodes: [],
      variables: {}
    }
  },
  computed: {
    isCreate() {
      return this.$route.query.edit != 1
    }
  },
  mounted() {
    this.loading = true
    this.init()
    this.loading = false
  },
  methods: {
    setNodes(nodes) {
      this.nodes = nodes
    },
    setAuditors(auditors) {
      this.params.auditors = auditors
    },
    async init() {
      try {
        const { orderCode } = this.$route.query
        const res = await this.$api.apply.invoiceOrderInfo({ orderCode })
        if (res) {
          let { orderResult, detailResults } = res
          detailResults = detailResults.map(a => {
            return {
              ...a,
              price: a.price / 100,
              amount: a.amount / 100
            }
          })
          let params = {
            ...orderResult,
            detailUpdateInputs: detailResults,
            auditors: {}
          }
          this.params = params
          if (this.params.relationOrderCode) {
            this.showCustomAdd = false
          }
        }
        this.sellOrderCodes = await this.$api.apply.listSellOrderCode({
          orderType: 4
        })
      } catch (err) {
        console.log(err)
      }
    },
    //申请人改变
    proposerChange(data) {
      this.$nextTick(() => {
        this.$refs.SelectDepartment?.changeEvent(data.cdepCode)
      })
    },
    async changeRelationOrderCode(relationOrderCode) {
      this.params.relationOrderCode = relationOrderCode
      if (this.params.relationOrderCode) {
        this.showCustomAdd = false
        let detail = await this.$api.apply.sellOrderDetail({
          orderCode: relationOrderCode
        })
        this.params.sellOrderCode = detail?.u8SellCode
        if (this.params.sellOrderCode) {
          this.existSellOrderCode = true
        } else {
          this.existSellOrderCode = false
        }
        let details = await this.$api.apply.sellOrderDetails({
          orderCode: relationOrderCode
        })
        this.params.detailUpdateInputs = details?.map(item => {
          return {
            code: item.code,
            name: item.name,
            num: item.num,
            price: item.price / 100,
            amount: item.totalAmount / 100,
            remark: item.remark
          }
        })
      } else {
        this.showCustomAdd = true
        this.params.detailUpdateInputs = []
        this.existSellOrderCode = false
      }
    },
    changeClient(data) {
      this.params.clientNo = data.ccusRegCode || ''
      this.params.clientPhone = data.ccusHand || ''
      this.params.clientBank = data.ccusBank || ''
      this.params.clientBankNo = data.ccusAccount || ''
      this.params.clientAddress = data.ccusAddress || ''
    },
    addEvent() {
      this.params.detailUpdateInputs.push({
        code: '',
        name: '',
        num: '',
        price: '',
        amount: '',
        remark: ''
      })
    },
    deleteEvent(index) {
      this.params.detailUpdateInputs = this.params.detailUpdateInputs.filter(
        (a, i) => i !== index
      )
    },
    //计算金额
    computeTotalAmount(index) {
      let item = this.$utils.deepClone(this.params.detailUpdateInputs[index])
      const { num, price } = item
      let amount = 0
      if (!num || !price) {
        amount = 0
      } else {
        amount = Number(num) * Number(price)
      }
      item = { ...item, amount }
      this.$set(this.params.detailUpdateInputs, index, item)
    },
    submitEvent(submit) {
      if (this.loading) return
      let params = this.$utils.deepClone(this.params)
      params.orderTime = params.orderTime + ' 00:00:00'
      if (params.fileList?.length > 0) {
        params.attachmentUrl = params.fileList
          .map(a => {
            return `${a.url}?fileName=${a.name}`
          })
          .join(',')
        delete params.fileList
      }
      let detailUpdateInputs = params.detailUpdateInputs.map(item => {
        item.price = item.price * 100
        item.amount = item.amount * 100
        return item
      })
      delete params.detailUpdateInputs
      let newParams = {
        submit: submit == 1,
        detailUpdateInputs,
        updateInput: params
      }
      if (submit != 1) {
        try {
          this.loading = true
          this.$api.apply.invoiceOrderSave(newParams).then(result => {
            this.$message.success('草稿保存成功')
          })
        } finally {
          this.loading = false
        }
      } else {
        if (this.nodes.length) {
          let flag = true
          for (let i = 0; i < this.nodes.length; i++) {
            if (
              this.nodes[i].assigneeType == 'SELF_SELECT' &&
              this.nodes[i].userType == 2 &&
              this.nodes[i].users.length == 0 &&
              !this.params?.auditors[this.nodes[i].id]?.length
            ) {
              flag = false
              return this.$message.warning('请选择审批人后提交')
            }
          }
          console.log('flag', flag)
          if (!flag) return
        }
        this.$refs['form'].validate(async valid => {
          if (valid) {
            try {
              if (this.params.detailUpdateInputs.length === 0) {
                this.$message.warning('请至少添加一条材料清单')
                return
              }
              this.loading = true
              clearInterval(this.timeSaveFlag)
              await this.$api.apply.invoiceOrderSave(newParams)
              //提交成功
              this.submitSuccess('/#/invoiceOrderApply')
            } finally {
              this.loading = false
            }
          }
        })
      }
    }
  }
}
</script>
