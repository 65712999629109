//申请单mixins
export default {
  data() {
    return {
      timeSaveFlag: null, //自动保存草稿定时器
      loading: false
    }
  },
  mounted() {
    if (this.submitEvent) {
      clearInterval(this.timeSaveFlag)
      this.timeSaveFlag = setInterval(this.submitEvent, 120000, 2)
    }
  },
  methods: {
    //提交成功
    submitSuccess(closeUrl) {
      this.$refs['form'].resetFields()
      this.$refs['UploadFile']?.valueInit()
      this.$message.success('申请成功，即将跳转到我的申请页面')
      this.$utils.debounce(
        this.isCreate
          ? this.$utils.parentIframeJump
          : this.$refs.page.returnEvent,
        3000,
        'jumpFlag'
      )('/#/myApply')
      this.$utils.debounce(
        this.$utils.parentIframeTabClose,
        3100,
        'tabCloseFlag'
      )(closeUrl)
    }
  },
  beforeDestroy() {
    clearInterval(this.timeSaveFlag)
  }
}
